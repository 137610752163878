<template>
  <div>
    
    <div class="d-flex justify-content-between flex-wrap">
            <div class="ml-0 mb-1 mb-sm-0">

              <div class="row">

                <div class="col-md-4" v-if="commodity_slug == null">
                  <div class="form-group">
                    <select name="commodity_slug" id="commodity_slug" v-model="filter.commodity_slug" class="form-control">
                      <option selected value="">-- Select Asset --</option>
                      <option v-for="commodity in commodities" v-bind:key="commodity.slug" v-bind:value="commodity.slug">{{ commodity.name }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <select name="warehouse_slug" id="warehouse_slug" v-model="filter.warehouse_slug" class="form-control">
                      <option selected value="">-- Select Depository --</option>
                      <option v-for="warehouse in warehouses" v-bind:key="warehouse.slug" v-bind:value="warehouse.slug">{{ warehouse.name }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4 mb-1">

                  <date-picker placeholder="Daterange" v-model="filter.daterange_filter" :value="filter.daterange_filter" range format="YYYY/MM/DD" range-separator="-" input-class="form-control"></date-picker>

                </div>

              </div>
              
              
            </div>
            <div class="d-flex align-content-center mb-1 mb-sm-0">
              <h1 class="font-weight-bolder ">
                {{ lastPrice != null ? lastPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' }}
              </h1>
            </div>
          </div>

          <vue-apex-charts
            v-if="priceCharts.chartOptions.xaxis.categories.length > 0"
            type="line"
            height="300"
            :options="priceCharts.chartOptions"
            :series="priceCharts.series"
          />

          <div class="row" v-if="lastPrice != null">
            <div class="col-md-6"></div>
            <div class="col-md-6 text-right">
              <small>Last Price:</small>
              <h1 class="font-weight-bolder">
                {{ currency.value }} {{ lastPrice != null ? lastPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' }}
              </h1>
            </div>
          </div>

          <div v-if="priceCharts.chartOptions.xaxis.categories.length == 0" class="text-center">
            <div v-if="chartIsLoading">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </div>
            <div v-else>Data is empty</div>
          </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue2-datepicker/index.css';

export default {
  components: {
    VueApexCharts,
    BSpinner,
    DatePicker,
  },
  props: [
    'commodity_slug', 
  ],
  data() {
    return {
      filter: {
        commodity_slug: '',
        warehouse_slug: '',
        daterange_filter: [],
      },
      warehouses: [],
      commodities: [],
      priceCharts: {
        series: [
          {
            name: 'Last Price',
            data: [], // Price
          },
        ],
        chartOptions: {
          stroke: {
          width: 2,
        },
        zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          xaxis: {
            type: 'datetime',
            categories: [], // Date
          }
        },
      },
      chartIsLoading: false,
      lastPrice: null,
      currency: {
        value : null
      }
    }
  },
  watch: {
    filter: {
      handler: function(){
        this.loadCommodityPrice();  
      },
      deep: true
    },
  },
  created() {
    this.loadFilterCommodityPrice();

    var startDate = moment().subtract(1, 'years').format('YYYY/MM/DD');
    var endDate = moment().format('YYYY/MM/DD');
    this.filter.daterange_filter = [new Date(String(startDate)), new Date(String(endDate))]
  },
  methods: {
    loadFilterCommodityPrice() {

      this.$http.get('/public/commodities/warehouses')
      .then(response => {
        this.warehouses = response.data.data
      })

      if (this.commodity_slug == null) {
        this.$http.get('/public/commodities/')
        .then(response => {
          this.commodities = response.data.data
        })
      } else {
        this.filter.commodity_slug = this.commodity_slug;
      }

    },
    loadCommodityPrice() {
      this.priceCharts.chartOptions.xaxis.categories = [];
      var queryParams = this.filter
      queryParams.start_date = moment(queryParams.daterange_filter[0]).format('YYYY-MM-DD');
      queryParams.end_date = moment(queryParams.daterange_filter[1]).format('YYYY-MM-DD');

      if (queryParams.commodity_slug != '' && queryParams.warehouse_slug != '') {
        this.chartIsLoading = true;
        this.$http.get('/public/commodities/price-history', {
          params: queryParams
        })
        .then(response => {
          this.priceCharts.chartOptions.xaxis.categories = response.data.data.map(item => item.time);
          this.priceCharts.series[0].data = response.data.data.map(item => item.price);
          if (response.data.data.length > 0) {
            this.currency.value = response.data.data[response.data.data.length - 1].currency
            this.lastPrice = response.data.data[response.data.data.length - 1].price;
          }
          this.chartIsLoading = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>